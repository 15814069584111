import React from 'react'
import Frequently from '../../component/Frequently/Frequently'

const Faq = () => {
  return (
    <div>
    <Frequently/>
      
    </div>
  )
}

export default Faq
