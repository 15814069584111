import React from 'react'
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import Navbar from '../navbar/Navbar';
import Header from '../Header/Header';
const Frequently = () => {
  return (
    <>
    <Header/>
    <Navbar/>

    <div class="inner-banner img-adjust">
    <div class="linear-left"></div>
    <div class="linear-center"></div>
    <div class="linear-right"></div>
    <div class="container">
        <h2 class="inner-banner-title">Faq</h2>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="">Home</a></li>
                <li class="breadcrumb-item active" aria-current="page">Faq</li>
            </ol>
        </nav>
    </div>
</div>


    <div class="faq-section position-relative pt-110 pb-110">
    <div class="linear-right"></div>
    <div class="container">
        <div class="row align-items-start gy-5">
            <div class="col-xl-5 text-start pe-lg-5">
                <div class="section-title mb-60">
                    <h2>Frequently Asked Questions</h2>
                    <p>Your Queries Answered: Unveiling the Essentials of Crypto Trading and Investment with FinFunder</p>
                </div>
                <a href="" class="i-btn btn--primary btn--lg capsuled">More Questions ?</a>
                <div class="bet-vecotr style-two">
                    <img src="default/images/385x278.jpg" alt="Vector image"/>
                </div>
            </div>
            <div class="col-xl-7">
                <div class="faq-wrap style-border">
                <MDBContainer className="mt-5" style={{maxWidth: '1000px'}}>
      <MDBAccordion alwaysOpen initialActive={1}>
        <MDBAccordionItem collapseId={1} headerTitle="How it works?">
        The bot, according to the specified parameters and based on AI, automatically opens transactions on the futures market in the BTC/USDT pair, sets entry points and exit points. The bot uses the trading balance of all users in small parts. This minimizes risks and allows you to always be in profit both on the long and at a short distance
        </MDBAccordionItem>
        <MDBAccordionItem collapseId={2} headerTitle=" Mathematics of earnings">
        The bot brings an average of 0.5 - 0.66% per day  from the deposit.
However do not forget about our commission.

For example:
Your deposit 1000 USDT
When replenishing, our commission will be 15%, which means that your balance will be credited 850  USDT
1 day  - 856USDT
2 day  - 863USDT
3 day  - 870USDT
4 day  - 877USDT
5 day  - 884USDT
6 day  - 891USDT
7 day  - 898USDT
8 day  - 905USDT
9 day  - 912USDT
10 day - 919USDT
11 day - 926 USDT
12 day - 933USDT


933 USDT will be available for withdrawal. The commission is 5%, 887 USDT will be credited to your balance, taking into account all commissions.

        </MDBAccordionItem>
        <MDBAccordionItem collapseId={3} headerTitle="How to make a deposit??">
        To top up the trading balance, go to the menu section "My account" - "Top up the balance" - copy the wallet number *USDT BEP20* (click on it) and transfer USDT to it. After you've made the transfer, click on the "Check payment" button, the transfer is realized automatically. The commission for replenishment is 15%.

❗️Attention ! Replenishment is realized only to the USDT BEP20 wallet! 
The minimum deposit amount is 25 USDT!

        </MDBAccordionItem>

        <MDBAccordionItem collapseId={4} headerTitle=" How to withdraw money??">
        To withdraw money, go to the menu section "My account" - "Withdrawal of funds" - "Withdrawal request" enter the required available amount and the USDT BEP20 wallet. 
The withdrawal process is automatic and takes from 24 minutes to 24-72 hours. The maximum withdrawal time is up to daily .
During the consideration, trading for your account will be stopped.


❗️Attention ! Replenishment is realized only to the USDT BEP20 wallet! 
The minimum amount is 5 USDT!

        </MDBAccordionItem>

        <MDBAccordionItem collapseId={5} headerTitle=" What is the commission?">
        We do not charge a commission for each completed transaction in the bot, but we charge 15% of the deposit amount and 6% of the withdrawal amount

        </MDBAccordionItem>

        <MDBAccordionItem collapseId={6} headerTitle="Term of bot's work?">
        The bot has been working for 16 months. During this time it has been upgraded many times, and its algorithms are being refined by our specialists and AI every day, which allows it to be actual for such a long time

Token treasure

To ensure security and the impossibility of stealing tokens, the total balance of tokens is divided into several wallets inside our system and the bot is integrated into each of these wallets

Possible risks

All risks are minimized: a large number of transactions, separate wallets, lack of trading leverage, proper risk management makes our bot practically invulnerable

Loss of tokens

Despite the fact that there are negative transactions, the loss cannot happen, since the bot enters into each transaction not for your entire deposit, but only for a part of it, also in each transaction the bot sets a stop loss of no more than 2%

Referral system

In order to earn 5% of all deposits of invited users, you need them to click on your link: to do this, go to the menu section "My account" - "Referral system" there will be your link for invitations (to copy the link just to it) in the same section you will see the number of invited users and the amount of earnings from them
 
a reward of 5% from each deposit of the listed users for level 1 and level 2. After first deposit of user when he deposited second time the user get 5% of deposit himself and 5% for the direct upline

Output duration

Only a small part of each user's deposit is involved in each transaction to diversify risks, so after a request to withdraw tokens, the bot needs time to withdraw all your tokens from circulation. Therefore, the duration of withdrawal funds is up to 7 days

Available transactions

The bot works on the futures market, without trading leverage. At the moment, only in the USDT/BTC pair

Minimum deposit

The minimum deposit amount is 25 USDT, 
Any restrictions?

At the moment there is no such restriction on the deposit amount. Theoretically it may occur if the amount of funds exceeds the possible implementation of the bot, in which case the income of each partner will decrease significantly or it will be necessary to set the maximum capital of our pool

Bot stop loss points

The bot sets a stop loss at a value of no more than 2%, which makes trading as safe as possible

Building a trust

Every day in the channel we publish the results of trading with our bot, there is also an open chat with real users of our service.
To check the performance of our bot, we recommend starting with a 50 USDT deposit, getting the first profit within a week, making a withdrawal of funds and then making conclusions

Account replenishment

Funds are credited to the account instantly, immediately after replenishing the wallet, click "Check payment" and in case of successful payment, your funds will instantly be displayed in your personal account.
        </MDBAccordionItem>
      </MDBAccordion>
    </MDBContainer>
                                  
                </div>
            </div>
        </div>
    </div>
</div>
</>
  )
}

export default Frequently
