import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { firebase } from '../../Firebase/config';
import { FaUserCircle } from 'react-icons/fa'; // Import the icon

const Navbar = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);

      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          try {
            const userRef = firebase.firestore().collection("users").doc(user.uid);
            const doc = await userRef.get();
            if (doc.exists) {
              setUserData(doc.data());
            } else {
              console.log("No user data found");
            }
          } catch (error) {
            console.error("Error fetching user data: ", error);
          }
        } else {
          console.log("No user signed in");
        }
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchUserData();
  }, []);

  return (
    <div>
      <header className="header-area style-1">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <div className="header-left">
            <button className="header-item-btn sidebar-btn d-lg-none d-block">
              <i className="bi bi-bars"></i>
            </button>

            <div className="header-logo">
              <a href="/">
                <img src="https://gainbot.io/assets/files/FEStVr9r2DrfajwT.png" alt="White Logo" />
              </a>
            </div>
          </div>

          <div className="main-nav">
            <div className="mobile-logo-area d-xl-none d-flex justify-content-between align-items-center">
              <div className="mobile-logo-wrap">
                <img src="https://gainbot.io/assets/files/FEStVr9r2DrfajwT.png" alt="White Logo" />
              </div>
              <div className="menu-close-btn">
                <i className="bi bi-x-lg"></i>
              </div>
            </div>
            <ul className="menu-list">
              <li className="menu-item-has-children">
                <a href="" className="drop-down active">Home</a>
              </li>
              <li className="menu-item-has-children">
                <a href="" className="drop-down">Trade</a>
              </li>
              {/* <li>
                <a href="">Pricing</a>
              </li> */}
              <li>
                <a href="">Features</a>
              </li>
              <li>
                <a href="#">
                  <Link to="/faq">FAQ</Link>
                </a>
              </li>
              <li>
                <a href="" className="">Contact</a>
              </li>
            </ul>

       
          </div>

          <div className="nav-right">
            {userData ? (
              <a
                href="/Profile"
                className="i-btn btn--md d-xl-flex d-none capsuled btn--primary-outline"
              >
                <FaUserCircle size={28} /> {/* Show icon when userData is available */}
              </a>
            ) : (
              <a
                href=""
                className="i-btn btn--md d-xl-flex d-none capsuled btn--primary-outline"
              >
                <a href="/login">Sign In</a> {/* Show Sign In text when no userData */}
              </a>
            )}

            <div className="sidebar-btn d-xl-none d-flex">
              <i className="bi bi-list"></i>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbar;
