import logo from './logo.svg';
import './App.css';
import './css/main.css';
import './css/bootstrap.min.css';
// import './js/new.js';
// import './js/bootstrap.bundle.min.js'
import Home from './pages/home/Home';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Faq from './pages/faq/Faq';
import Profile from './pages/Profile';
import { useState, useEffect } from "react";
import { firebase } from '../src/Firebase/config';
import Dashboard from './pages/Admin/Dashboard';
function App() {

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [percentageAmount, setPercentageAmount] = useState(0);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [myuser, setMyuser] = useState(null);
  const [hasUpdatedToday, setHasUpdatedToday] = useState(false); // Track if update has been made today

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          setMyuser(user);
          try {
            const userRef = firebase.firestore().collection("users").doc(user.uid);

            // Set up real-time listener
            userRef.onSnapshot((doc) => {
              if (doc.exists) {
                const data = doc.data();
                if (JSON.stringify(userData) !== JSON.stringify(data)) {
                  console.log("Data updated:", data);
                  setUserData(data);
                  setLoading(false);
                  const transactions = data.Transaction || [];
                  const depositTransactions = transactions
                  .filter((transaction) => 
                      transaction.title === "Deposit for gainbot" && transaction.Status === "Paid"
                  )
                  .map((transaction) => parseFloat(transaction.amount));

                  const totalAmount = depositTransactions.reduce((acc, curr) => acc + curr, 0);
                  const deductionCharge = totalAmount * 0.15;
                  const adjustedTotal = totalAmount - deductionCharge;
                  const percentage = adjustedTotal * 0.0056;

                  setPercentageAmount(percentage);

                  if (data.lastUpdated) {
                    setLastUpdated(data.lastUpdated.toDate());
                    console.log(`Last update time: ${data.lastUpdated.toDate()}`);
                  }
                } else {
                  console.log("No data update, forcing income distribution.");
                  // Call distributeInvestmentIncome when there are no data updates
                  distributeInvestmentIncome();
                }
              } else {
                console.log("No user data found");
              }
            });
          } catch (error) {
            console.error("Error fetching user data: ", error);
          }
        } else {
          console.log("No user signed in");
        }
        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchUserData();
  }, [myuser, userData]);

  useEffect(() => {
    const checkAndUpdateInvestmentTransaction = async () => {
      if (!myuser || !lastUpdated) return;
  
      const now = new Date();
      const lastUpdateDate = new Date(lastUpdated);
  
      if (now.toDateString() !== lastUpdateDate.toDateString() && !hasUpdatedToday) {
        await distributeInvestmentIncome(); // Update missing dates
        setHasUpdatedToday(true); 
        console.log(`Data updated today at ${now.toLocaleTimeString()}`);
      } else {
        console.log("Data was already updated today.");
      }
    };
  
    checkAndUpdateInvestmentTransaction();
    const interval = setInterval(() => {
      checkAndUpdateInvestmentTransaction();
    }, 60 * 1000);
  
    return () => clearInterval(interval);
  }, [lastUpdated, myuser, hasUpdatedToday]);
  
  const distributeInvestmentIncome = async () => {
    try {
      const userRef = firebase.firestore().collection('users').doc(myuser.uid);
      const userDoc = await userRef.get();
      const transactions = userDoc.data().Transaction || [];
  
      // Find the first and last investment income transaction dates
      const investmentIncomeTransactions = transactions
        .filter((transaction) => transaction.title.startsWith("Investment Income"))
        .map((transaction) => new Date(transaction.date))
        .sort((a, b) => a - b); // Sort by date, earliest first
  
      let firstIncomeDate = investmentIncomeTransactions[0]; // Earliest date
      let lastIncomeDate = investmentIncomeTransactions[investmentIncomeTransactions.length - 1]; // Latest date
  
      console.log("First income date:", firstIncomeDate);
      console.log("Last income date:", lastIncomeDate);
  
      // If no last income date, use lastUpdated date
      if (!lastIncomeDate) {
        lastIncomeDate = lastUpdated ? lastUpdated.toDate() : null;
      }
  
      // If there's no last income or last update date, return
      if (!lastIncomeDate) {
        console.error('No last update date available to determine missing days.');
        return;
      }
  
      // Calculate the total number of days between the first and last income date
      const daysBetweenFirstAndLast = Math.floor((lastIncomeDate - firstIncomeDate) / (1000 * 60 * 60 * 24));
      console.log(`Total days between first and last income date: ${daysBetweenFirstAndLast}`);
  
      // Find missing dates between the first and last income date
      const missingDates = [];
      let currentDate = new Date(firstIncomeDate);
      currentDate.setDate(currentDate.getDate() + 1); // Start from the day after the first income date
  
      while (currentDate <= lastIncomeDate) {
        const dateString = currentDate.toLocaleDateString();
        const existingIncomeForDate = transactions.find(
          (transaction) => transaction.title === `Investment Income of ${dateString}`
        );
  
        if (!existingIncomeForDate) {
          missingDates.push(new Date(currentDate)); // Add missing date
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
  
      // Check how many dates are missing
      const totalMissingDays = missingDates.length;
      console.log(`Missing investment income for ${totalMissingDays} days.`);
      console.log("Missing dates:", missingDates.map(date => date.toLocaleDateString()));
  
      // Count the total number of investment income transactions
      const totalInvestmentIncomeCount = investmentIncomeTransactions.length;
      console.log(`Total number of investment income transactions: ${totalInvestmentIncomeCount}`);
  
      // If no missing dates, return
      if (totalMissingDays === 0) {
        console.log('No missing dates found. All investment income transactions are up to date.');
        return;
      }
  
      // Add transactions for missing dates
      const batch = firebase.firestore().batch();
      missingDates.forEach((missingDate) => {
        const dateString = missingDate.toLocaleDateString();
        const uniqueDepositId = `InvestmentIncome_${myuser.uid}_${missingDate.toISOString()}`;
  
        const depositData = {
          id: uniqueDepositId,
          amount: percentageAmount.toFixed(2),
          date: missingDate.toISOString(),
          method: 'Deposit',
          title: `Investment Income of ${dateString}`,
        };
  
        batch.update(userRef, {
          Transaction: firebase.firestore.FieldValue.arrayUnion(depositData),
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        });
      });
  
      await batch.commit(); // Commit all the updates in a batch
      console.log(`Missing transactions updated for ${totalMissingDays} days.`);
  
    } catch (error) {
      console.error('Error updating missing transactions: ', error);
    }
  };
  
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setMyuser(user);
        const userRef = firebase.firestore().collection('users').doc(user.uid);

        // Listen for changes in the user's data
        const unsubscribeUserData = userRef.onSnapshot((doc) => {
          if (doc.exists) {
            setUserData(doc.data());
          } else {
            console.log('No user data found');
          }
        });

        // Listen for changes in the users collection
        const usersRef = firebase.firestore().collection('users');
        const unsubscribeUsersData = usersRef.onSnapshot((snapshot) => {
          const usersList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
          setUsersData(usersList);
          calculatedistributeDirectncome(usersList); // Call to calculate income whenever users data changes
        });

        return () => {
          unsubscribeUserData();
          unsubscribeUsersData();
        };
      } else {
        console.log('No user signed in');
        setLoading(false);
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const calculatedistributeDirectncome = async (usersList) => {
    if (!myuser || !userData) return; // Ensure user data is available

    const userTokenId = userData.tokenId;

    const level1Users = usersList.filter((user) => {
      const referralIds = user.referralId ? user.referralId.split(',') : [];
      return referralIds[0] === userTokenId;
    });

    const level2Users = usersList.filter((user) => {
      const referralIds = user.referralId ? user.referralId.split(',') : [];
      return referralIds[1] === userTokenId;
    });

    let totalIncome = 0;

    // Update transactions for level1 and level2 users
    for (let level1User of level1Users) {
      const depositData = await distributeDirectncome(level1User, 0.05); // 5% for Level 1
      totalIncome += depositData; // Sum up the total income
    }

    for (let level2User of level2Users) {
      const depositData = await distributeDirectncome(level2User, 0.05); // 5% for Level 2
      totalIncome += depositData;
    }

    // Update total money state
    // setTotalMoney((prevTotal) => prevTotal + totalIncome);
  };

  const distributeDirectncome = async (user, percentage) => {
    try {
      const currentDateTime = new Date().toISOString();
      const transactions = user.Transaction;

      if (!transactions || transactions.length === 0) {
        console.log(`No transactions found for user: ${user.name}`);
        return 0;
      }

      const filteredTransactions = transactions.filter(
        (transaction) => transaction.title === "Deposit for gainbot"
      );

      if (filteredTransactions.length === 0) {
        console.log(`No relevant transactions found for user: ${user.name}`);
        return 0;
      }

      let totalIncome = 0;

      for (let transaction of filteredTransactions) {
        const transactionAmount = parseFloat(transaction.amount || 0);
        const incomeFromTransaction = transactionAmount * percentage;
        totalIncome += incomeFromTransaction;

        const mybalance = parseFloat(user.totalbalance) + incomeFromTransaction;

        const uniqueDepositId = `DirectIncome_${user.id}_${transaction.title}_${transaction.date}`;

        const existingDeposits = await firebase.firestore().collection('users')
          .doc(myuser.uid)
          .get()
          .then(doc => doc.data().Transaction || [])
          .then(transactions => transactions.filter(deposit => deposit.id === uniqueDepositId));

        if (existingDeposits.length === 0) {
          const depositData = {
            id: uniqueDepositId,
            amount: incomeFromTransaction.toFixed(2),
            date: currentDateTime,
            paymentdate: `${transaction.date}`,
            method: 'Deposit',
            title: `Direct Income from ${user.name} ${user.lname}`,
            // totalbalance: mybalance,
          };

          await firebase.firestore().collection('users').doc(myuser.uid).update({
            Transaction: firebase.firestore.FieldValue.arrayUnion(depositData),
            totalbalance: firebase.firestore.FieldValue.increment(incomeFromTransaction),
          });

          console.log(`Transaction updated for user: ${user.name}, Income from ${transaction.title}: + ₹${incomeFromTransaction}`);
        } else {
          console.log(`Deposit already exists for user: ${user.name} for transaction: ${transaction.title}`);
        }
      }

      return totalIncome;
    } catch (error) {
      console.error('Error updating transaction: ', error);
      return 0;
    }
  };

  // Function to calculate total balance from transactions
  const calculateTotalBalance = (transactions) => {
    return transactions.reduce((total, transaction) => total + parseFloat(transaction.amount || 0), 0);
  };

  useEffect(() => {
    if (userData && userData.Transaction) {
      const totalBalance = calculateTotalBalance(userData.Transaction);
      // setTotalMoney(totalBalance);
      console.log("userdata", userData, "Total Balance from Transactions:", totalBalance);
    }
  }, [userData]);
  return (
   <>
   <BrowserRouter>
   <Routes>
   <Route path='/' element={<Home/>}/>
   <Route path='/login' element={<Login/>}/>
   <Route path='/register' element={<Register/>}/>
   <Route path='/faq' element={<Faq/>}/>
   <Route path='/Profile' element={<Profile/>}/>
   <Route path='/Admin/Dashboard' element={<Dashboard/>}/>
   </Routes>
   </BrowserRouter>
  

   </>
  );
}

export default App;
