import React, { useState, useEffect } from 'react';
import { firebase } from '../Firebase/config';
import { BeatLoader } from 'react-spinners';
import { 
  FaTachometerAlt, 
  FaUser, 
  FaWallet, 
  FaUsers, 
  FaChartLine, 
  FaLifeRing, 
  FaUserCircle, 
  FaBars, 
  FaTimes ,
  FaSignOutAlt
} from 'react-icons/fa';
import 'tailwindcss/tailwind.css';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../component/Dashboard';
import Directincome from '../component/Refferal/Directincome';
import Investmentincome from '../component/Refferal/Investmentincome';
import LevelIncome from '../component/Refferal/Levelincome';
import RetopupIncome from '../component/Refferal/Retopup';
import Walllet from '../component/Wallet';
import Profiles from '../component/Profile';
const Profile = () => {
  const navigate = useNavigate(); 
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('Dashboard');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State for sidebar visibility
  const [usersData, setUsersData] = useState([]);
  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          try {
            const userRef = firebase.firestore().collection('users').doc(user.uid);
            const doc = await userRef.get();
            if (doc.exists) {
              setUserData(doc.data());
              const usersRef = firebase.firestore().collection('users');
              const unsubscribeUsersData = usersRef.onSnapshot((snapshot) => {
                const usersList = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
                setUsersData(usersList);
              });
              return () => unsubscribeUsersData(); // Clean up the users listener
            } else {
              console.log('No user data found');
            }
          } catch (error) {
            console.error('Error fetching user data: ', error);
          }
        } else {
          console.log('No user signed in');
        }
        setLoading(false);
      });
      return () => unsubscribe();
    };
    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      await firebase.auth().signOut();
      navigate('/')
      // Redirect to login or home page after logout if needed
    } catch (error) {
      console.error('Error logging out: ', error);
    }
  };

  // Function to render content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'Dashboard':
        return <Dashboard userData={userData} usersData={usersData} />;
      case 'Profile':
        return <Profiles/>;
      case 'Direct Income':
        return <div><Directincome/></div>;
      case 'Investment Income':
        return <div><Investmentincome/></div>;
      case 'Level Income':
        return <div>
          <LevelIncome/>
        </div>;
      case 'Re Top-Up Income':
        return <div>
          <RetopupIncome/>
        </div>;
      // case 'My Downline':
      //   return <div>My Downline Content</div>;
      // case 'My Earning':
      //   return <div>My Earning Content</div>;
      case 'Wallet':
        return <div><Walllet/></div>;
      default:
        return <div>Dashboard Content</div>;
    }
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Mobile Menu Icon (FAB) */}
      <button
        className="absolute top-4 right-4 z-50 p-2 bg-gray-900 text-white rounded-full lg:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <FaTimes /> : <FaBars />}
      </button>

      {/* Sidebar */}
      <div className={`fixed inset-y-0 left-0 z-50 w-64 bg-gray-900 text-white flex flex-col transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static lg:w-64`}>
        <div className="p-4 text-center text-xl font-bold border-b border-gray-700">Dashboard</div>
        <nav className="flex flex-col space-y-4 mt-4 p-4">
          {/* Sidebar links */}
          {[
            { name: 'Dashboard', icon: <FaTachometerAlt /> },
            { name: 'Profile', icon: <FaUser /> },
            { name: 'Direct Income', icon: <FaWallet /> },
            { name: 'Investment Income', icon: <FaChartLine /> },
            { name: 'Level Income', icon: <FaUsers /> },
            { name: 'Re Top-Up Income', icon: <FaLifeRing /> },
            // { name: 'My Downline', icon: <FaUsers /> },
            // { name: 'My Earning', icon: <FaChartLine /> },
            { name: 'Wallet', icon: <FaWallet /> },
            { name: 'Logout', icon: <FaSignOutAlt /> } // Add logout option
          ].map((tab, index) => (
            <button
              key={index}
              className={`flex items-center space-x-2 p-2 rounded   hover:bg-white hover:text-black ${activeTab === tab.name ? 'bg-black text-white' : ''}`}
              onClick={() => {
                if (tab.name === 'Logout') {
                  handleLogout(); // Handle logout
                } else {
                  setActiveTab(tab.name);
                }
              }}
            >
              {tab.icon}
              <span>{tab.name}</span>
            </button>
          ))}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8">
        <div className="text-2xl font-bold mb-4">{activeTab}</div>
        <div>{renderContent()}</div>
      </div>
    </div>
  );
};

export default Profile;
