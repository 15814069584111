import React, { useState, useEffect } from 'react';
import { FaCopy, FaShareAlt, FaShare,FaWallet, FaUsers, FaCaretUp, FaExchangeAlt  } from 'react-icons/fa';

const Dashboard = ({ userData,usersData }) => {

  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const textField = document.createElement('textarea');
    textField.innerText = `http://gainbot.techbabua.com/register?referral=${userData?.tokenId}`;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const shareReferral = async () => {
    try {
      await navigator.share({
        title: 'Share Referral Link',
        text: 'Join me on GainBot and earn rewards!',
        url: `http://gainbot.techbabua.com/register?referral=${userData?.tokenId}`,
      });
    } catch (error) {
      console.error('Sharing failed', error);
    }
  };

  console.log("userdata", userData);

  // Calculating amounts
  const transactions = userData?.Transaction || [];
  
  // Investment
  const myInvestment = transactions
  .filter(tx => tx.title === "Deposit for gainbot" && tx.Status === "Paid")
  .reduce((total, tx) => total + parseFloat(tx.amount || 0), 0);


  // Profit
  const myProfit = transactions
    .filter(tx => tx.title.startsWith("Investment Income"))
    .reduce((total, tx) => total + parseFloat(tx.amount || 0), 0);

  // Wallet Balance
  const walletBalance = transactions
  .filter(tx => tx.method.startsWith("Deposit") && (tx.Status === "Paid" || !tx.Status))
  .reduce((total, tx) => total + parseFloat(tx.amount || 0), 0);


  console.log("wallet balance", walletBalance); // Log wallet balance

  // Total Profit excluding "Deposit for gainbot"
  const totalProfit = transactions
    .filter(tx => tx.title !== "Deposit for gainbot")
    .reduce((total, tx) => total + parseFloat(tx.amount || 0), 0);

  console.log("total profit", totalProfit); // Log total profit
  const userTokenId = userData?.tokenId;
  const totalUsers = usersData.filter(user => {
    const referralIds = user.referralId ? user.referralId.split(',') : [];
    return referralIds.includes(userTokenId);
  }).length;

  // Find total direct users (where referralId matches userTokenId as the first entry)
  const totalDirect = usersData.filter(user => {
    const referralIds = user.referralId ? user.referralId.split(',') : [];
    return referralIds[0] === userTokenId;
  }).length;
  return (
    <div className="min-h-screen ">
      <h1 className='text-4xl py-4 font-bold text-black'>
        Welcome, {userData?.name}
      </h1>
      <div className="bg-white shadow-lg p-6 rounded-lg mb-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">Your Referral Code</h2>
        <div className="flex flex-col space-y-4">
          <div className="flex items-center bg-gray-50 p-4 rounded-lg shadow">
            <input
              type="text"
              readOnly
              value={
                userData && userData.tokenId
                  ? `http://gainbot.techbabua.com/register?referral=${userData.tokenId}`
                  : ''
              }
              className="flex-1 w-full py-2 px-4 bg-transparent border-none text-gray-900"
            />
            <button
              onClick={copyToClipboard}
              className={`ml-4 px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 w-36 rounded-lg transition-all ${
                copied ? 'hidden' : 'block'
              }`}
            >
              Copy
            </button>
            {copied && <span className="ml-4 text-green-500">Copied!</span>}
          </div>

          {/* Share Button */}
          <button
            onClick={shareReferral}
            className="py-2 px-4 w-full text-white bg-green-600 hover:bg-green-700 rounded-lg flex items-center justify-center transition-all"
          >
            <FaShare className="mr-2" />
            Share Now
          </button>
        </div>
      </div>
      <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 xl:w-1/2 p-6">
        <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-green-600">
                <FaWallet className="fa-2x text-white" />
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h2 className="font-bold uppercase text-gray-600">My Investment</h2>
              <p className="font-bold text-3xl">${myInvestment.toFixed(2)} <span className="text-green-500"><FaCaretUp /></span></p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 xl:w-1/2 p-6">
        <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-green-600">
                <FaWallet className="fa-2x text-white" />
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h2 className="font-bold uppercase text-gray-600">My Profit</h2>
              <p className="font-bold text-3xl">${myProfit.toFixed(2)} <span className="text-green-500"><FaCaretUp /></span></p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 xl:w-1/2 p-6">
        <div className="bg-gradient-to-b from-pink-200 to-pink-100 border-b-4 border-pink-500 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-pink-600">
                <FaUsers className="fa-2x text-white" />
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h2 className="font-bold uppercase text-gray-600">My Direct</h2>
              <p className="font-bold text-3xl">{totalDirect} <span className="text-pink-500"><FaExchangeAlt /></span></p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/2 xl:w-1/2 p-6">
        <div className="bg-gradient-to-b from-pink-200 to-pink-100 border-b-4 border-pink-500 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-pink-600">
                <FaUsers className="fa-2x text-white" />
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h2 className="font-bold uppercase text-gray-600">My Team</h2>
              <p className="font-bold text-3xl">{totalUsers} <span className="text-pink-500"><FaExchangeAlt /></span></p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/2 xl:w-1/2 p-6">
        <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-green-600">
                <FaWallet className="fa-2x text-white" />
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h2 className="font-bold uppercase text-gray-600">Wallet Balance</h2>
              <p className="font-bold text-3xl">${walletBalance.toFixed(2)} <span className="text-green-500"><FaCaretUp /></span></p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:w-1/2 xl:w-1/2 p-6">
        <div className="bg-gradient-to-b from-green-200 to-green-100 border-b-4 border-green-600 rounded-lg shadow-xl p-5">
          <div className="flex flex-row items-center">
            <div className="flex-shrink pr-4">
              <div className="rounded-full p-5 bg-green-600">
                <FaWallet className="fa-2x text-white" />
              </div>
            </div>
            <div className="flex-1 text-right md:text-center">
              <h2 className="font-bold uppercase text-gray-600">Total Profit</h2>
              <p className="font-bold text-3xl">${totalProfit.toFixed(2)} <span className="text-green-500"><FaCaretUp /></span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Dashboard;
